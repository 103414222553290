.App {
  text-align: center;
  font-family: 'DM Sans', sans-serif;
}

:root {
  --mainBlue: #1737E5;
  --mainYellow: #EACB30;
  --mainGrey: #545454;
  --mainBlack: #111111;
  --mainGold: #FFFBE5;
  --lightBlue: #F2F6FF;
}

.owl-dots {

  text-align: center;
  margin-left: auto;
  right: 0;
  position: absolute;
  z-index: 1;

}


.owl-dot.active span {
  background-color: #EACB30 !important;
  outline: 0;

}
@media(max-width:426px){
  .owl-dots{
    position:relative;
    margin:auto;
  }
}